import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { Layout } from "../components/Layout";
import { Button } from "../components/Button";
import { layout } from "../constants";
import parse from "html-react-parser";
import format from "date-fns/format";
import { nl } from "date-fns/locale";
import { getSrc } from "gatsby-plugin-image";

export const BlogPostPage: React.VFC<PageProps<{ post: any }>> = (props) => {
  const { post } = props.data;
  return (
    <Layout
      headerVariation="small"
      title={post.title}
      ogType="article"
      description={post.excerpt}
      ogImage={getSrc(post.featuredImage?.node)}
    >
      <div
        className={`prose sm:prose-lg md:prose-xl mx-auto ${layout.ySpacing}`}
      >
        <article itemScope itemType="http://schema.org/BlogPosting">
          <h1 itemProp="headline" className="!mb-3">
            {post.title}
          </h1>
          <meta itemProp="url" content={post.uri} />
          <meta itemProp="author" content="Peter Hamersma" />
          <meta itemProp="dateModified" content={post.modified} />
          <aside className="mb-4 text-mid text-sm">
            <time itemProp="datePublished" dateTime={post.date}>
              {format(new Date(post.date), "PPpp", { locale: nl })}
            </time>
            {/* {post.modified !== post.date ? (
              <>
                <br />
                Aangepast op{" "}
                <time itemProp="dateModified" dateTime={post.modified}>
                  {format(new Date(post.modified), "PPpp", { locale: nl })}
                </time>
              </>
            ) : null} */}
          </aside>
          <div itemProp="articleBody">{parse(post.content)}</div>
        </article>

        <footer className="mt-10">
          <Button to="/updates">Lees meer updates…</Button>
        </footer>
      </div>
    </Layout>
  );
};

export default BlogPostPage;

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date
      modified
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;
